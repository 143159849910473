import amplitude from 'amplitude-js';

export enum AmplitudeEvents {
  paymentsSearched = 'payments-searched',
  refundSubmitted = 'refund-submitted',
  refundExported = 'refund-exported',
  financesViewed = 'finances-viewed',
  accountAdded = 'account-added',
  accountVerified = 'account-verified',
  accountRemoved = 'account-removed',
  signedIn = 'signed-in',
  sessionOpened = 'session-opened',
  sessionClosed = 'session-closed',
  emailVerificationSuccess = 'email-verification-success',
  emailVerificationFailed = 'email-verification-failed',
  accessRestricted = 'access-restricted',
  fileTransferIntegrationSelected = 'file-transfer-integration-selected',
  fileTransferIntegrationStarted = 'file-transfer-integration-started',
  fileTransferIntegrationCompleted = 'file-transfer-integration-completed',
  fileTransferRemitSpecUploaded = 'file-transfer-remit-spec-uploaded',
  fileTransferIntegrationEditStarted = 'file-transfer-integration-edit-started',
  fileTransferIntegrationEditCompleted = 'file-transfer-integration-edit-completed',
  webhookIntegrationOpened = 'webhook-integration-opened',
  webhookIntegrationSelected = 'webhook-integration-selected',
  webhookIntegrationStarted = 'webhook-integration-started',
  webhookIntegrationCompleted = 'webhook-integration-completed',
  webhookIntegrationTestRequested = 'webhook-integration-test-requested',
  webhookIntegrationTestSent = 'webhook-integration-test-sent',
  bankAccountVerificationStarted = 'bank-account-verification-started',
  bankAccountVerificationFailed = 'bank-account-verification-failed',
  bankAccountFormOpened = 'bank-account-form-opened',
  bankAccountBillingAddressClicked = 'bank-account-billing-address-clicked',
  bankAccountTaxIdClicked = 'bank-account-tax-id-clicked',
  refundStarted = 'refund-started',
  refundHistoryOpened = 'refund-history-opened',
  submittedRefundClicked = 'submitted-refund-clicked',
  transactionHistoryOpened = 'transaction-history-opened',
  minValidationLengthClicked = 'min-validation-length-clicked',
  maxValidationLengthClicked = 'max-validation-length-clicked',
  paymentSettingsRegExClicked = 'payment-settings-reg-ex-clicked',
  adminRegistrationStep1Completed = 'admin-registration-step-1-completed',
  adminRegistrationStep2Completed = 'admin-registration-step-2-completed',
  adminRegistrationStep3Completed = 'admin-registration-step-3-completed',
  membersInvited = 'members-invited',
  memberActivated = 'member-activated',
  memberDeactivated = 'member-deactivated',
  memberRoleChanged = 'member-role-changed',
  roleAdded = 'role-added',
  modalDocsAccessed = 'modal-docs-accessed',
  webhookConfiguration = 'webhook-сonfiguration',
  sftpConfiguration = 'sftp-configuration',
  sftpFileUpload = 'sftp-fileUpload',
  sftpUploadHistory = 'sftp-uploadHistory',
  ppUserInfo = 'pp-user-info',
  ppBusinessRegistered = 'pp-business-registered',
  emailVerificationCodeResent = 'email-verification-code-resent',
  ppProductSelected = 'pp-product-selected',
  ppProductDeselected = 'pp-product-deselected',
  ppBusinessInfoIconTapped = 'pp-business-info-icon-tapped',
  previousTapped = 'previous-tapped',
  ppBusinessInfoSubmitted = 'pp-business-info-submitted',
  ppQuickStartTapped = 'pp-quick-start-tapped',
  breadcrumbTapped = 'breadcrumb-tapped',
}

export type AmplitudeEventParams = Record<string, unknown>;

export enum AmplitudeVariants {}

export class Amplitude {
  private static analytics: amplitude.AmplitudeClient;

  public static init(analyticsApiKey: string) {
    this.analytics = amplitude.getInstance();
    this.analytics.init(analyticsApiKey);
  }

  public static setUser(userId?: string) {
    this.analytics.setUserId(userId ?? null);
  }

  public static logEvent(event: AmplitudeEvents, params?: AmplitudeEventParams) {
    this.analytics.logEvent(event.toString(), params);
  }
}
