import React from 'react';

import { Icon } from '../../../../shared';
import { Amplitude, AmplitudeEvents } from '../../../../shared/services/amplitude';

interface MainTabsProps {
  value: number;
  changeTab: () => void;
}
export default function MainTabs({ value, changeTab }: MainTabsProps) {
  const handleClick = (tabTitle: string) => {
    changeTab();
    Amplitude.logEvent(AmplitudeEvents.breadcrumbTapped, {
      tab: tabTitle,
    });
  };

  return (
    <div className="loan__tabs">
      <button
        onClick={() => handleClick('repayment plan')}
        type="button"
        className={value === 1 ? 'loan__tab--active' : 'loan__tab--disabled'}
      >
        <Icon name="chart-pie" />
        Repayment plan
      </button>
      <button
        onClick={() => handleClick('loan summary')}
        type="button"
        className={value === 2 ? 'loan__tab--active' : 'loan__tab--disabled'}
      >
        <Icon name="book-open-solid" />
        Loan summary
      </button>
    </div>
  );
}
